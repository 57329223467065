import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="car-filter"
export default class extends Controller {
  static targets = ["checkbox", "filterForm"];

  connect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.submitForm.bind(this));
    });
  }

  submitForm() {
    this.element.submit();
  }

  clearFilters() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });
    this.filterFormTarget.requestSubmit();
  }
}
