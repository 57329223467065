import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout-car"
export default class extends Controller {
  static targets = ["dateRange", "optionalFeatures", "totalDays", "totalCost", "optionalFeaturesSelected", "paymentAmount"]

  connect() {
    this.updateCheckout()

    // Add event listeners to checkboxes
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange.bind(this))
    })
  }

  disconnect() {
    // Remove event listeners to avoid memory leaks
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.removeEventListener('change', this.handleCheckboxChange.bind(this))
    })
  }

  updateCheckout() {
    const dateRange = this.dateRangeTarget.value.split(" to ");
    let totalDays = 0;

    if (dateRange.length === 2) {
      const pickUpDate = new Date(dateRange[0]);
      const dropOffDate = new Date(dateRange[1]);
      totalDays = (dropOffDate - pickUpDate) / (1000 * 60 * 60 * 24) + 1;
    } else if (dateRange.length === 1 && dateRange[0] !== "") {
      totalDays = 1;
    }

    // Display total days
    this.totalDaysTarget.textContent = `${totalDays} days`;

    // Calculate costs
    const carCostPerDay = parseFloat(this.element.dataset.carCostPerDay);
    const userCurrency = this.element.dataset.userCurrency;
    const exchangeRate = parseFloat(this.element.dataset.exchangeRate);
    
    const carTotalCost = totalDays * carCostPerDay;

    // Optional Features Calculation
    const selectedFeatures = this.element.querySelectorAll('input[type="checkbox"]:checked');
    let optionalFeaturesCost = 0;
    let selectedFeaturesDetails = "";

    // Log selected features for debugging
    // console.log("Selected Optional Features:", Array.from(selectedFeatures).map(feature => ({
    //   name: feature.dataset.featureName,
    //   cost: feature.dataset.featureCost
    // })));

    selectedFeatures.forEach(feature => {
      const featureCost = parseFloat(feature.dataset.featureCost);
      const featureCostDisplay = (featureCost/100)*exchangeRate;
      optionalFeaturesCost += featureCost;
      selectedFeaturesDetails += `
        <div class="d-flex justify-content-between">
          <p>${feature.dataset.featureName}</p>
          <p>${userCurrency} ${featureCostDisplay.toFixed(2)}</p>
        </div>
      `;
    });

    // Calculate the total cost including optional features
    const totalCost = carTotalCost + optionalFeaturesCost;

    const totalCostDisplay = (carTotalCost/100)*exchangeRate;
    const paymentAmountDisplay = (totalCost/100)*exchangeRate;

    // Update the DOM elements
    this.totalCostTarget.textContent = `${userCurrency} ${totalCostDisplay.toFixed(2)}`;
    this.paymentAmountTarget.textContent = `${userCurrency} ${paymentAmountDisplay.toFixed(2)}`;

    // Update hidden field with total cost
    const totalCostField = document.querySelector('input[name="booking[total_cost]"]');
    if (totalCostField) {
      totalCostField.value = totalCost.toFixed(2);
    }

    if (selectedFeatures.length > 0) {
      this.optionalFeaturesSelectedTarget.innerHTML = selectedFeaturesDetails;
    } else {
      this.optionalFeaturesSelectedTarget.innerHTML = "<p>No Optional Features Selected</p>";
    }
  }

  handleCheckboxChange(event) {
    // console.log("Checkbox Changed:");
    // console.log("Name:", event.target.dataset.featureName);
    // console.log("Cost:", event.target.dataset.featureCost);
    // console.log("Checked:", event.target.checked);

    // Update checkout on checkbox change
    this.updateCheckout();
  }
}
