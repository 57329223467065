import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout-attraction"
export default class extends Controller {
  static targets = ["bookingDate", "optionalFeatures", "optionalFeaturesSelected", "adultTickets", "childrenTickets", "adultTicketInput", "childrenTicketInput", "paymentAmount"]

  connect() {

    // booking dates flatpickr
    flatpickr(this.bookingDateTarget, {
      altInput: true,
      altFormat: "F j, Y",
      minDate: "today",
      dateFormat: "Y-m-d",
    });

    // Add event listeners to checkboxes
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange.bind(this))
    })

    this.updateCheckout()
  }

  disconnect() {
    // Remove event listeners to avoid memory leaks
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.removeEventListener('change', this.handleCheckboxChange.bind(this))
    })

    this.adultTicketsTarget.removeEventListener('input', this.updateCheckout.bind(this));
    this.childrenTicketsTarget.removeEventListener('input', this.updateCheckout.bind(this));
  }
  
  updateCheckout() {

    const userCurrency = this.element.dataset.userCurrency;
    const exchangeRate = parseFloat(this.element.dataset.exchangeRate);

    // Get the number of adult and children tickets
    const adultTickets = parseInt(this.adultTicketInputTarget.value) || 0;
    const childrenTickets = parseInt(this.childrenTicketInputTarget.value) || 0;

    // Get the costs per ticket
    const adultTicketCost = parseFloat(this.adultTicketInputTarget.dataset.ticketCost) || 0;
    const childrenTicketCost = parseFloat(this.childrenTicketInputTarget.dataset.ticketCost) || 0;

    // Calculate ticket costs
    const totalAdultTicketsCost = adultTickets * adultTicketCost;
    const totalAdultTicketsCostDisplay = (totalAdultTicketsCost/100)*exchangeRate;
    const totalChildrenTicketsCost = childrenTickets * childrenTicketCost;
    const totalChildrenTicketsCostDisplay = (totalChildrenTicketsCost/100)*exchangeRate;
    
    // Optional Features Calculation
    const selectedFeatures = this.element.querySelectorAll('input[type="checkbox"]:checked');
    let optionalFeaturesCost = 0;
    let selectedFeaturesDetails = "";

    selectedFeatures.forEach(feature => {
      const featureCost = parseFloat(feature.dataset.featureCost);
      optionalFeaturesCost += featureCost;
      selectedFeaturesDetails += `
        <div class="d-flex justify-content-between">
          <p>${feature.dataset.featureName}</p>
          <p>${userCurrency} ${featureCost.toFixed(2)}</p>
        </div>
      `;
    });

    // Calculate the total cost including tickets and optional features
    const totalCost = totalAdultTicketsCost + totalChildrenTicketsCost + optionalFeaturesCost;
    const totalCostDisplay = (totalCost/100)*exchangeRate;

    // Update the DOM elements
    this.paymentAmountTarget.textContent = `Total ${userCurrency} ${totalCostDisplay.toFixed(2)}`;

    // Update hidden field with total cost
    const totalCostField = document.querySelector('input[name="booking[total_cost]"]');
    if (totalCostField) {
        totalCostField.value = totalCost.toFixed(2);
    }

    // Update selected features display
    if (selectedFeatures.length > 0) {
        this.optionalFeaturesSelectedTarget.innerHTML = selectedFeaturesDetails;
    } else {
        this.optionalFeaturesSelectedTarget.innerHTML = "";
    }

    // Update adult and children tickets display
    this.adultTicketsTarget.textContent = `${adultTickets} Adults = ${userCurrency} ${totalAdultTicketsCostDisplay.toFixed(2)}`;
    this.childrenTicketsTarget.textContent = `${childrenTickets} Children = ${userCurrency} ${totalChildrenTicketsCostDisplay.toFixed(2)}`;
  }

  handleCheckboxChange(event) {
    // console.log("Checkbox Changed:");
    // console.log("Name:", event.target.dataset.featureName);
    // console.log("Cost:", event.target.dataset.featureCost);
    // console.log("Checked:", event.target.checked);

    // Update checkout on checkbox change
    this.updateCheckout();
  }
}
