import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTargets.forEach(button => {
      button.addEventListener("click", this.toggleAccordion.bind(this));
    });
  }

  toggleAccordion(event) {
    const itemToggle = event.currentTarget.getAttribute('aria-expanded');

    this.buttonTargets.forEach(button => {
      button.setAttribute('aria-expanded', 'false');
    });

    if (itemToggle === 'false') {
      event.currentTarget.setAttribute('aria-expanded', 'true');
    }
  }
}
