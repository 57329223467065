import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

// Connects to data-controller="booking-car-flatpickr"
export default class extends Controller {
  static targets = ["carPickupTime", "carDropoffTime"];

  connect() {
    const bookingForm = document.getElementById('booking-form-div');
    if (bookingForm) {
      const bookings = JSON.parse(bookingForm.dataset.bookings || '[]');
      flatpickr("#date-range-picker", {
        mode: "range",
        altInput: true,
        altFormat: "F j, Y",
        minDate: "today",
        dateFormat: "Y-m-d",
        disable: bookings,
        onChange: this.updateDates.bind(this)
      });
    } else {
      console.log("Booking form not found");
    }

    flatpickr(this.carPickupTimeTarget, {
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      dateFormat: "H:i:S", // Capture hours, minutes, and seconds
      onChange: function(selectedDates, dateStr, instance) {
        this.carPickupTimeTarget.value = dateStr; // Update the input field with formatted time
      }.bind(this)
    });

    flatpickr(this.carDropoffTimeTarget, {
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      dateFormat: "H:i:S", // Capture hours, minutes, and seconds
      onChange: function(selectedDates, dateStr, instance) {
        this.carDropoffTimeTarget.value = dateStr; // Update the input field with formatted time
      }.bind(this)
    });
  }

  updateDates(selectedDates, dateStr, instance) {
    if (selectedDates.length > 0) {
      const pickUpDate = selectedDates[0].toISOString().split('T')[0];
      document.querySelector('#range_start').value = pickUpDate;

      if (selectedDates.length === 2) {
        const dropOffDate = selectedDates[1].toISOString().split('T')[0];
        document.querySelector('#range_end').value = dropOffDate;
      } else {
        document.querySelector('#range_end').value = ''; // Clear drop_off_date if not selected
      }
    }
  }
}
