import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="car-filter-mobile"
export default class extends Controller {
  static targets = ["checkbox", "filterForm", "submitButton"];

  connect() {
    this.submitButtonTarget.addEventListener('click', this.submitForm.bind(this));
  }

  submitForm(event) {
    event.preventDefault();
    this.filterFormTarget.submit();
  }

  clearFilters() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });
    this.filterFormTarget.requestSubmit();
  }
}
